<template>
    <div id="contacts-newsletter"> 
      <contacts-list-newsletter></contacts-list-newsletter>
    </div>
  </template>
  
  <script>
    import ContactsListNewsletter from './components/ContactsListNewsletter'
  
    export default{
      name: 'ContactsNewsletter',
      components: {ContactsListNewsletter},
      beforeMount() {
        
      },
      methods: {
       
      },
    }
  </script>
  